// Uses undocumented method from recreaction.gov to get rating for a facility
const FetchFacilityRatingFromRIDB = {
    // Fetch facilities from RIDB
    async fetchFacilityRatingByID(FacilityID = '') { 
        const params = {
            location_id: FacilityID,
            location_type: 'Campground' 
        }
        let urlParams = '';
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                urlParams += key + '=' + params[key] + '&'
            }
        }
        const url = 'https://www.recreation.gov/api/ratingreview/aggregate?' + urlParams;
        // const urlWithApiKey = url + 'apikey=' + process.env.REACT_APP_RIDB_API_KEY;
        const urlCorsAnywhere = 'https://camp-scout-proxy.herokuapp.com/' + url; 
        const data = await fetch(urlCorsAnywhere);
        return data;
    }
}

export default FetchFacilityRatingFromRIDB;
