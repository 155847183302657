import React from 'react';
import './ExplorePage.css';


class ExplorePage extends React.Component {

  render() {
    return (
      <div className="ExplorePage">
        <h3>Explore Places</h3>
        <p>Find a place to camp faster, with less stress</p>
      </div>
    );
  }
}

export default ExplorePage;
