import React, { useState, useRef, useEffect } from 'react';
import './LocationSearchInput.css';
import Button from '../Button/Button';
import { debounce } from 'lodash';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function LocationSearchInput(props) {
    // Query params to set state from the selected suggestion
    const [campground, setCampground] = useQueryParam('id', StringParam); // This is used only for FacilityPage
    const [placeLabel, setPlaceLabel] = useQueryParam('place', StringParam);
    const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
    const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);

    // Resource: https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/
    // Resource: https://sherryhsu.medium.com/react-async-data-fetch-f5ccb107d02b
    // Resource: https://www.pluralsight.com/guides/fetching-data-updating-state-hooks
    // Resource: https://blog.devgenius.io/how-to-create-a-real-time-search-using-debounce-in-react-js-846a62ad2198

    // Component states
    const [userText, setUserText] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [campgroundSuggestions, setCampgroundSuggestions] = useState([]);
    const [placeSuggestions, setPlaceSuggestions] = useState([]);
    // const [suggestionsLoading, setSuggestionsLoading] = useState(true);

    // Generalize the state of the location input
    const hasCampground = campground !== undefined && campground !== null;
    const hasPlaceLabel = placeLabel !== undefined && placeLabel !== null;
    const hasLatitudeLongitude = latitude !== undefined && latitude !== null && typeof latitude === 'number' && longitude !== undefined && longitude !== null && typeof longitude === 'number';


    // State from props
    const locationSearchInputIsActive = props.locationSearchInputIsActive;
    const setLocationSearchInputIsActive = props.setLocationSearchInputIsActive;
    const showOnlyLocationSearchInput = props.showOnlyLocationSearchInput;
    const closeDialog = props.closeDialog;

    // Set focus into input on mount
    // Resource: https://reactgo.com/react-focus-input/
    // Resource: https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering
    const inputRef = useRef(null);
    useEffect(() => {
        // current property is referene to input element
        if (locationSearchInputIsActive) {
            inputRef.current.focus();
        }
    })

    // Fetch for suggestions
    async function fetchSuggestions(text) {
        const url = `https://www.recreation.gov/api/search/suggest?q=${text}&geocoder=true`
        const urlCorsAnywhere = 'https://camp-scout-proxy.herokuapp.com/' + url; // This isn't needed from a cors standpoint but on 1/9/2022 6:15am PT I brought down their server with some bad looping code. So, this will rate limit me at 100 req/minute.
        setIsFetching(true);
        const response = await fetch(urlCorsAnywhere)
        const results = await response.json();
        setIsFetching(false);
        console.log('Suggestion Response', results);

        // Filter out facility suggestion to only campgrounds
        let validCampgrounds = [];

        if ('inventory_suggestions' in results && Array.isArray(results['inventory_suggestions'])) {
            results['inventory_suggestions'].forEach((campground, index) => {
                if (campground['entity_type'] === 'campground' && campground['entity_id'] !== null && campground.name !== null) {
                    validCampgrounds.push(campground);
                }
            })
        }
        setCampgroundSuggestions(validCampgrounds);
        // Set place suggestions
        setPlaceSuggestions(results.suggestions);
    }

    // create memoized version of debounced data fetch function
    // Resource: https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/
    const debouncedFetchData = useRef(debounce(nextValue => fetchSuggestions(nextValue), 700))
        .current;

    // call memoized debounced function in the event handler so the intermediate searches are cancelled by debounce while the user types
    function handleTextChange(e) {
        const { value: nextValue } = e.target; // Not sure exactly how this works
        setUserText(nextValue);
        // Even though handleChange is created on each render and executed
        // it references the same debouncedSave that was created initially
        // If user clears what they type, don't fetch suggestions
        if (nextValue.length > 0) {
            debouncedFetchData(nextValue);
        }
    }

    function handleOnClickPlace(e, clickedPlace) {
        e.preventDefault();
        console.log('handleClickPlace', clickedPlace);
        setPlaceLabel(clickedPlace.text);
        setLatitude(Math.round(clickedPlace.lat * 1000000, 8) / 1000000);
        setLongitude(Math.round(clickedPlace.lng * 1000000, 8) / 1000000);
        // After selection, clear the search experience
        setUserText(clickedPlace.text); // Use place text for next time it is opened
        setPlaceSuggestions([]);
        setCampgroundSuggestions([]);
        setLocationSearchInputIsActive(false);

        // On facilities page, when only updating location, close after choosing a place
        if (showOnlyLocationSearchInput) {
            closeDialog();
            // Reset to active state for next time they open it
            setLocationSearchInputIsActive(true);
        }
    }

    function handleOnClickCampground(e, clickedCampground) {
        e.preventDefault();
        console.log('handleClickCampground', clickedCampground);
        // Store campground ID as a URL query param
        setCampground(clickedCampground['entity_id']);
        // Store place label (campground) as a URL query param
        const placeName = formatPlaceName(clickedCampground.name + ', ' + clickedCampground['state_code']);
        const placeNameEncoded = encodeURIComponent(placeName);
        setPlaceLabel(placeNameEncoded);
        // After selection, clear the search experience
        setUserText(placeName); // Use place text for next time it is opened
        setCampgroundSuggestions([]);
        setPlaceSuggestions([]);
        setLocationSearchInputIsActive(false);
    }

    function handleGetCurrentLocation(e) {
        navigator.geolocation.getCurrentPosition(function (position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            setPlaceLabel('Near me');
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            setUserText(''); // TODO: Figure out why it's not clearing the input
            setLocationSearchInputIsActive(false);
            // On facilities page, when only updating location, close after choosing a place
            if (showOnlyLocationSearchInput) {
                closeDialog();
                // Reset to active state for next time they open it
                setLocationSearchInputIsActive(true);
            }
        });
    }

    function formatPlaceName(placeText) {
        const words = placeText.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        return words.join(' ');
    }

    // Display different things below the input based on conditions
    const placeItems = !isFetching && placeSuggestions.length === 0 || placeSuggestions === null || placeSuggestions === undefined ? (
        <div className='LocationSearchInput-near-me' onClick={handleGetCurrentLocation}>
            <Button size='md' type='tertiary'>Find campgrounds near me</Button>
        </div>
    ) : (
        <div>
            <h3>Places</h3>
            {placeSuggestions.map((place, index) => {
                return (
                    <div className='LocationSearchInput-suggestion'
                        key={place.text}
                        onClick={(e) => { handleOnClickPlace(e, place) }}>
                        <div className='LocationSearchInput-campground-suggestion-primary'>{place.text}</div>
                    </div>
                )
            })
            }
        </div>
    )

    let campgroundItems = '';
    if (campgroundSuggestions.length !== 0) {
        campgroundItems = (
            <div>
                <h3>Campgrounds</h3>
                {
                    campgroundSuggestions.map((campground, index) => {
                        // According to this I shouldn't use index as keys https://reactjs.org/docs/lists-and-keys.html
                        return (
                            <div className='LocationSearchInput-suggestion'
                                key={campground['entity_id'].toString()}
                                onClick={(e) => { handleOnClickCampground(e, campground) }}>
                                <div className='LocationSearchInput-campground-suggestion-primary'>{formatPlaceName(campground.name)}</div>
                                <div className='LocationSearchInput-campground-suggestion-secondary'>
                                    <span className='LocationSearchInput-campground-suggestion-org-name'>{campground['org_name']}</span>
                                    {/* <span className='LocationSearchInput-campground-suggestion-city'>{campground.city}</span> */}
                                    <span className='LocationSearchInput-campground-suggestion-state'>{campground['state_code']}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    // Loading state
    const loadingState = isFetching ? (
        <div className='LocationSearchInput-loading'>Loading...</div>
    ) : <div></div>;

    // If inactive, clicking on the button will make it active
    function handleActivateInput() {
        setLocationSearchInputIsActive(true);
    }

    // What shows in the button
    // This logic is duplicated in LocationInputButton. TODO: DRY
    let buttonText = 'Where do you want to go camping?';
    if (hasPlaceLabel) {
        buttonText = decodeURIComponent(placeLabel);
    }
    else if (!hasPlaceLabel && hasCampground) {
        buttonText = 'Campground ' + campground;
    }
    else if (hasLatitudeLongitude && !hasPlaceLabel) {
        buttonText = 'Map Location'
    }


    // Render the location search input OR simply a button summary based on whether the user has clicked it (isActive)
    if (locationSearchInputIsActive) {
        return (
            <div className='LocationSearchInput'>
                <div className='LocationSearchInput-text-input'>
                    <input type="textarea" ref={inputRef} placeholder='Where do you want to go camping?' autoFocus autoComplete="off" name="userText" value={userText} onChange={handleTextChange} />
                </div>
                <div className='LocationSearchInput-suggestions-container'>
                    {loadingState}
                    <div className='LocationSearchInput-places'>
                        {placeItems}
                    </div>
                    <div className='LocationSearchInput-campgrounds'>
                        {campgroundItems}
                    </div>
                </div>
            </div>
        )
    }
    else {
        return <div className='LocationSearchInput'>
            <div className='LocationSearchInput-text-input inactive' onClick={handleActivateInput}>{buttonText}</div>
        </div>
    }
}

export default LocationSearchInput