import React, { useEffect, useState } from 'react';
import './FacilitiesPage.css';
// import FetchFacilitiesFromRIDB from '../../util/FetchFacilitiesFromRIDB';
import FacilitiesList from '../FacilitiesList/FacilitiesList';
import FacilitiesMap from '../FacilitiesMap/FacilitiesMap';
import FacilitiesSearchHeader from '../FacilitiesSearchHeader/FacilitiesSearchHeader';
import Button from '../Button/Button';
import {
    useQueryParam,
    QueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    DelimitedArrayParam,
    withDefault,
    BooleanParam,
} from 'use-query-params';

function FacilitiesPage() {
    // TODO: Move URL params to app level and pass down as props???
    // const [campgroundIds, setCampgroundIds] = useQueryParam('campgrounds', DelimitedArrayParam); // if not good, try DelimitedArrayParam
    const [userDefinedDateRanges, setUserDefinedDateRanges] = useQueryParam('dates', DelimitedArrayParam);
    const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);
    const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);
    const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);
    const [nextMonths, setNextMonths] = useQueryParam('nextmonths', NumberParam);
    const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
    const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);
    const [mapZoom, setMapZoom] = useQueryParam('mapzoom', NumberParam);
    // Mobile map parameter to toggling map and list on mobile
    const [showMap, setShowMap] = useQueryParam('showmap', BooleanParam);

    // State for facility items (to be passed to FacilityList component)
    const [facilityListItems, setFacilityListItems] = useState([]);
    const [facilityMapItems, setFacilityMapItems] = useState([]);
    const [facilityMapQueryCount, setFacilityMapQueryCount] = useState(0);
    const [facilityListIsLoading, setFacilityListIsLoading] = useState(true);
    // const [facilityListPendingUpdate, setFacilityListPendingUpdate] = useState(false);

    // State for window size for mobile view
    const [mediaQuery, setMediaQuery] = useState({
        matches: window.innerWidth < 624 ? true : false,
    }) // 768?

    // Check states in query params
    const userDefinedDateRangesIsEmpty = (userDefinedDateRanges === undefined || userDefinedDateRanges === null || userDefinedDateRanges.length === 0);
    const monthsOfYearIsEmpty = (monthsOfYear === undefined || monthsOfYear === null || monthsOfYear.length === 0);
    const nextMonthsIsEmpty = (nextMonths === undefined || nextMonths === null);
    const dateConstraintsIsEmpty = userDefinedDateRangesIsEmpty && monthsOfYearIsEmpty && nextMonthsIsEmpty;
    const consecutiveDaysIsEmpty = (consecutiveDays === undefined || consecutiveDays === null);
    const weekendDaysIsEmpty = (weekendDays === undefined || weekendDays === null);
    // Might need to move into useEffect
    const nlDateObj = {
        userDefinedDateRanges: userDefinedDateRanges,
        consecutiveDays: consecutiveDays,
        weekendDays: weekendDays,
        dateSearchWindow: {
            monthsOfYear: monthsOfYear,
            nextMonths: nextMonths
        }
    }

    // Use Effect will run run when the component mounts
    useEffect(() => {
        // Listen for window resizing
        let mQuery = window.matchMedia("(max-width: 624px)");
        mQuery.addListener(setMediaQuery);
        // This is the cleanup function to remove the listener
        return () => mQuery.removeListener(setMediaQuery);

        // Now we use the map to query...
        // FetchFacilitiesFromRIDB.generateFacilityList();
        // FetchFacilitiesFromRIDB.console.log('facilityListItems', facilityListItems);

    }, []); // Having an empty array here ensures it only executes on mount (important for not bombarding API each re-render)

    /* Creating the facility list involves:
        - Using the lat/lon provided in the URL (must have) to search facilities within x mile radius of centroid
        - For each facility returned, query the data store for the next available date
        - Render the Facility List passing the facility attributes and available dates as props
        - Via a toggle, it can render the Facility Map passing the facility attributes and availabe dates as props
    */

    // Use button over map to update list results when pressed
    function handleUpdateFacilityListItems() {
        // Update list items to rerender list
        setFacilityListItems(facilityMapItems);
        // Indicat not loading
        // setFacilityListIsLoading(false);
    }

    // Update query count to know if initially query
    function handleIncrementQueryCount() {
        // Straight count incrementer was not working, so now passing useState a functionResource: https://dev.to/nehal_mahida/how-not-to-update-states-in-react-33om
        console.log('New query from map')
        // For initial query, update list
        if (facilityMapQueryCount == 0) {
            // THIS IS NOT WORKING. REACT THINKS facilityMapQueryCount IS ALWAYS 0 HERE
            setFacilityListItems(facilityMapItems);
        }
        // Increment counter
        setFacilityMapQueryCount((prevState) => {
            return prevState + 1;
        });
    }

    // TODO: To reduce the frequency of API requests as you pan the map, 
    // after initial load only have FacilityList only update when you press this button
    const updateListButton = facilityListItems !== facilityMapItems && facilityMapQueryCount > 1 ? (
        <div className='FacilitiesPage-update-list-button'>
            <Button type='secondary' size='sm' onClick={handleUpdateFacilityListItems}>Update Search</Button>
        </div>
    ) : (
        <div className='FacilitiesPage-update-list-button' style={{ display: 'none' }} >See List</div>
    )



    // Conditionally show list and map or list/map toggle
    let pageContent = '';
    // If on mobile device, then conditionally display the list or map
    if (mediaQuery.matches) {
        if (showMap) {
            pageContent = (
                <div className="FacilitiesPage-search-results-container">
                    <div className="FacilitiesPage-map">
                        <FacilitiesMap 
                            setFacilityMapItems={setFacilityMapItems} 
                            setFacilityListIsLoading={setFacilityListIsLoading} 
                            handleIncrementQueryCount={handleIncrementQueryCount} 
                            nlDateObj={nlDateObj} 
                            dateConstraintsIsEmpty={dateConstraintsIsEmpty} 
                            latitude={latitude} 
                            longitude={longitude} />
                    </div>
                </div>
            )
        }
        else {
            pageContent = (
                <div className="FacilitiesPage-search-results-container">
                    <div className="FacilitiesPage-list ">
                        <FacilitiesList 
                        facilityListItems={facilityMapItems} 
                        nlDateObj={nlDateObj} 
                        dateConstraintsIsEmpty={dateConstraintsIsEmpty} 
                        isLoading={facilityListIsLoading} />
                    </div>
                </div>
            )
        }
    }
    // If not on mobile device, then display both list and map
    else {
        pageContent = (
            <div className="FacilitiesPage-search-results-container">
                <div className="FacilitiesPage-list">
                    <FacilitiesList 
                        facilityListItems={facilityMapItems} 
                        nlDateObj={nlDateObj} 
                        dateConstraintsIsEmpty={dateConstraintsIsEmpty} 
                        isLoading={facilityListIsLoading} />
                </div>
                <div className="FacilitiesPage-map">
                    <FacilitiesMap 
                        setFacilityMapItems={setFacilityMapItems} 
                        setFacilityListIsLoading={setFacilityListIsLoading} 
                        handleIncrementQueryCount={handleIncrementQueryCount} 
                        nlDateObj={nlDateObj} 
                        dateConstraintsIsEmpty={dateConstraintsIsEmpty} 
                        latitude={latitude} 
                        longitude={longitude} />
                </div>
            </div>
        )
    }
    return (
        <div className="FacilitiesPage">
            <div className='FacilitiesPage-search-header-outer'>
                <div className='FacilitiesPage-search-header-inner'>
                    <FacilitiesSearchHeader handleUpdateFacilityListItems={handleUpdateFacilityListItems} />
                </div>
            </div>
            {pageContent}
        </div>
    );
}

export default FacilitiesPage;
