/* TODO:
- extend the date prototype to include .addDays() and .dateOnly()? 
- Move over the date functions in AvailabilityDataStore.js?
*/

/* Natural Language Date Parameters Documentation 
userDefinedDateRanges: [[checkin, checkout], [checkin, checkout]] // If present, other parameters are ignored
consecutiveDays: 1..90,
weekendDays: undefined (no weekend constraint), 0 (weekdays only), 1 (only one night has to be a weekend night), 2 (standard two night weekend),
dateSearchWindow: {  // choose one of following
    undefined dateSearchWindow // search for next available -- not implemented
    period: 'today', 'tomorrow', 'thisWeekend'
    monthsOfYear: 0..11, // month of year base zero
    nextMonths: 1..6 // Include this month til end of x month from now
}

*/



// Date Engine
const dateEngine = {
    // Generate date ranges from natural language date properties 
    nlDatePropertiesToDateRanges(nlDateProperties) {
        // console.log('In Date Engine, nlDateObj', nlDateProperties)
        // If the following natural language date properties are present, this method isn't needed
        if ('userDefinedDateRanges' in nlDateProperties  && !(nlDateProperties.userDefinedDateRanges === undefined || nlDateProperties.userDefinedDateRanges === null)) { 
            return nlDateProperties.userDefinedDateRanges;
        }
        // If userDefinedDateRanges are present, a dateSearchWindow must be, otherwise return an empty array of date ranges
        if (!('dateSearchWindow' in nlDateProperties)) { 
            return [];
        }

        // Take natural language date properties and turn them into date ranges [[checkin, checkout], [checkin, checkout]]
        const consecutiveDays = nlDateProperties.consecutiveDays;
        const weekendDays = nlDateProperties.weekendDays;
        let resultingDateRanges = [];
        if ('period' in nlDateProperties.dateSearchWindow) {
            const period = nlDateProperties.dateSearchWindow.period;
            resultingDateRanges = this.periodToDateRanges(period, consecutiveDays, weekendDays);
        }
        else if ('monthsOfYear' in nlDateProperties.dateSearchWindow && Array.isArray(nlDateProperties.dateSearchWindow.monthsOfYear) && nlDateProperties.dateSearchWindow.monthsOfYear !== undefined) {
            // Bulk search based on months of year. Turn them into date ranges to search for.
            const monthsOfYear = nlDateProperties.dateSearchWindow.monthsOfYear;
            const dateWindows = this.monthsOfYearToDateSearchWindows(monthsOfYear);
            resultingDateRanges = this.dateSearchWindowsToDateRanges(dateWindows, consecutiveDays, weekendDays);
        }
        else if ('nextMonths' in nlDateProperties.dateSearchWindow && typeof nlDateProperties.dateSearchWindow.nextMonths === 'number') {
            // TODO: test to make sure it works for all scenarios
            // Governor: Limit to 6, as that's the typical limit
            const governor = 6;
            const nextMonths = Math.min(governor, nlDateProperties.dateSearchWindow.nextMonths);
            const monthsOfYear = this.nextMonthsToMonthsOfYear(nextMonths);
            const dateWindows = this.monthsOfYearToDateSearchWindows(monthsOfYear);
            resultingDateRanges = this.dateSearchWindowsToDateRanges(dateWindows, consecutiveDays, weekendDays)
        }
        else {
            console.log('Date generation properties not recognized')
        }
        // console.log('Calculated Date Ranges', resultingDateRanges);
        return resultingDateRanges;
    },
    periodToDateRanges(period, consecutiveDays, weekendDays) {
        // TODO: Make this method sensative to consecutive days and weekendDays
        // If tonight or tomorrow, then dateWindow.relativePeriod === 'tonight' is all that's needed;
        const periodIsToday = period === 'today';
        const periodIsTomorrow = period === 'tomorrow';
        const periodIsThisWeekend = period === 'thisWeekend';

        const today = this.dateToString(new Date());
        const tomorrow = this.addDays(today, 1);

        if (periodIsToday && consecutiveDays > 0) {
            const checkInDateStr = today;
            const checkOutDateStr = this.addDays(checkInDateStr, consecutiveDays);
            return [[checkInDateStr, checkOutDateStr]];
        }
        else if (periodIsTomorrow && consecutiveDays > 0) {
            const checkInDateStr = tomorrow;
            const checkOutDateStr = this.addDays(checkInDateStr, consecutiveDays);;
            return [[checkInDateStr, checkOutDateStr]];
        }
        else if (periodIsThisWeekend && consecutiveDays > 0 & weekendDays > 0) {
            const referenceDay = today;
            return this.dateSearchWindowsToDateRanges([referenceDay], consecutiveDays, weekendDays);
        }
        else {
            console.log('Relative period parameters incomplete')
        }
    },
    nextMonthsToMonthsOfYear(nextMonths) {
        if(nextMonths === undefined || typeof nextMonths !== 'number') {
            console.log('Incorrect number of next-months provided');
            return [];
        }
        let monthsOfYear = [];
        const todaysDate = new Date();
        // let currentMonth = todaysDate.getMonth();
        // let currentYear = todaysDate.getFullYear();
        for (let i = 0; i <= nextMonths; i++) {
            const result = new Date(todaysDate.getFullYear(), todaysDate.getMonth() + i, todaysDate.getDate());
            monthsOfYear.push(result.getMonth());
        }
        return monthsOfYear;
    },
    monthsOfYearToDateSearchWindows(arrayOfMonthNumbers) {
        // Sort array. JS treats numbers as strings, so do a different method
        arrayOfMonthNumbers.sort((a, b) => {
            // if( a === Infinity ) 
            //   return 1; 
            // else if( isNaN(a)) 
            //   return -1;
            // else 
              return a - b;
          });
        // Dates need to be converted to zero base
        const arrayOfMonthNumbersZB = arrayOfMonthNumbers.map(num => parseInt(num) - 1);

        let dateSearchWindows = [];
        const todaysDate = new Date();
        let currentMonth = todaysDate.getMonth();
        let currentYear = todaysDate.getFullYear();
        let possibleDateRange = []; // [checkinDateStr, checkoutDateStr]
        let rangeIndex = 0; // 0 is checkin, 1 is checkout
        // Start from today and go x months. If this month is April 2020, 'January' should be January 2021
        const monthsToLoop = 12;
        for (let i = 0; i < monthsToLoop; i++) {
            if (arrayOfMonthNumbersZB.includes(currentMonth)) {
                possibleDateRange[rangeIndex] = this.dateToString(new Date(currentYear, currentMonth, 1));
                rangeIndex = 1;
                possibleDateRange[rangeIndex] = this.dateToString(new Date(currentYear, currentMonth + 1, 0));
                rangeIndex = 0
                dateSearchWindows.push(possibleDateRange);
                possibleDateRange = [];
            }
            if (currentMonth === 11) { // Months are 0-indexed
                currentMonth = 0;
                currentYear++;
            }
            else {
                currentMonth++
            }
        }
        // If the end of one date search window buts up against another, then melt them together (consecutive months)
        let meltedDateSearchWindows = [];
        possibleDateRange = [];
        rangeIndex = 0;
        for (let i = 0; i < dateSearchWindows.length; i++) {
            if (possibleDateRange.length === 0) {
                possibleDateRange[rangeIndex] = dateSearchWindows[i][0];
                rangeIndex = 1;
            }
            if (i !== dateSearchWindows.length - 1 && dateSearchWindows[i][1] === this.addDays(dateSearchWindows[i + 1][0], -1)) {
                // Do nothing
            }
            else {
                possibleDateRange[rangeIndex] = dateSearchWindows[i][1]
                rangeIndex = 0;
                meltedDateSearchWindows.push(possibleDateRange);
                possibleDateRange = [];
            }

        }
        // console.log('meltedDateSearchWindows', meltedDateSearchWindows);
        return meltedDateSearchWindows;
    },
    // Takes general search windows (e.g., May, July) and returns [checkin, checkout] ranges accounting for consecutiveDays and weekendDays constraints
    dateSearchWindowsToDateRanges(searchDateWindows, consecutiveDays = 1, weekendDays = undefined) {
        // Catch invalid cases
        // console.log('searchDateWindow', searchDateWindows);
        if (searchDateWindows.length === 0) {
            console.log('No search date windows')
            return [];
        };

        if (consecutiveDays === undefined) {
            console.log('Consecutive day parameter undefined')
            return [];
        }
        // Push date ranges to dateRanges for each dateSearchWindow
        let dateRanges = [];
        searchDateWindows.forEach(searchDateWindow => {
            // searchDateWindow is an array of [startWindow, endWindow]
            // Non-weekend bound DOW range options
            if (weekendDays === undefined) {
                // In this scenario, day of week is irrelevant.
                // Instead, define start date based on dateSearchWindow[0] and go consecutiveDays forward
                // Then add one day to both checkin and checkout dates for next range. 
                // Note: The dateRanges.length will equal number of days in dateSearchWindow
                const startWindow = searchDateWindow[0];
                const endWindow = searchDateWindow[1];
                // Not sure about next line
                // const lastCheckinDate = this.addDays(endWindow, consecutiveDays);
                let currentDate = startWindow.slice();
                // let rangeIndex = 0; // 0 is checkin, 1 is checkout

                while (this.stringToDate(currentDate) < this.stringToDate(endWindow)) {
                    dateRanges.push([currentDate, this.addDays(currentDate, consecutiveDays)]);
                    currentDate = this.addDays(currentDate, 1);
                }
            }
            else {
                // If weekendDays is defined, then determine day of week options
                // if the search is constrained to days of week, then the following applies
                const sun = 0;
                const mon = 1;
                const tue = 2;
                const wed = 3;
                const thu = 4;
                const fri = 5;
                const sat = 6;

                let dowRangeOptions = [];
                // Partial weekend case
                if (weekendDays === 1) {
                    switch (consecutiveDays) {
                        case 1:
                            dowRangeOptions = [[fri, sat], [sat, sun]];
                            break;
                        case 2:
                            dowRangeOptions = [[thu, sat], [fri, sun], [sat, mon]];
                            break;
                        case 3:
                            dowRangeOptions = [[wed, sat], [thu, sun], [fri, mon], [sat, tue]];
                            break;
                        case 4:
                            dowRangeOptions = [[tue, sat], [wed, sun], [thu, mon], [fri, tue], [sat, wed]];
                            break;
                        case 5:
                            dowRangeOptions = [[mon, sat], [tue, sun], [wed, mon], [thu, tue], [fri, wed], [sat, thu]];
                            break;
                        case 6:
                            dowRangeOptions = [[sun, sat], [mon, sun], [tue, mon], [wed, tue], [thu, wed], [fri, thu], [sat, fri]];
                            break;
                        default:
                            dowRangeOptions = [];
                            console.log('Date search scenario not supported');
                    }

                }
                // Full weekend case
                else if (weekendDays === 2) {
                    switch (consecutiveDays) {
                        case 1:
                            dowRangeOptions = [[fri, sat], [sat, sun]];
                            break;
                        case 2:
                            dowRangeOptions = [[fri, sun]];
                            break;
                        case 3:
                            dowRangeOptions = [[thu, sun], [fri, mon]];
                            break;
                        case 4:
                            dowRangeOptions = [[wed, sun], [thu, mon], [fri, tue]];
                            break;
                        case 5:
                            dowRangeOptions = [[tue, sun], [wed, mon], [thu, tue], [fri, wed]];
                            break;
                        case 6:
                            dowRangeOptions = [[mon, sun], [tue, mon], [wed, tue], [thu, wed], [fri, thu]];
                            break;
                        default:
                            dowRangeOptions = [];
                            console.log('Date search scenario not supported');
                    }
                }
                // Not-weekend cases
                else if (weekendDays === 0) {
                    switch (consecutiveDays) {
                        case 1:
                            dowRangeOptions = [[sun, mon], [mon, tue], [tue, wed], [wed, thu], [thu, fri]];
                            break;
                        case 2:
                            dowRangeOptions = [[sun, tue], [mon, wed], [tue, thu], [wed, fri]];
                            break;
                        case 3:
                            dowRangeOptions = [[sun, wed], [mon, thu], [tue, fri]];
                            break;
                        case 4:
                            dowRangeOptions = [[sun, thu], [mon, fri]];
                            break;
                        case 5:
                            dowRangeOptions = [[sun, fri]];
                            break;
                        default:
                            dowRangeOptions = [];
                            console.log('Date search scenario not supported');
                    }
                }
                else {
                    dowRangeOptions = [];
                }
                // console.log('dowRangeOptions', dowRangeOptions);

                // Account for two scenarios: 
                // 1. Get all weekends between two dates 
                // 2. Get next weekend from a reference date
                // Note: I'm not actually sure if 2 will be used...
                const isRangeSearch = searchDateWindow.length === 2 ? true : false;

                // Now that we have dowRangeOptions, we can get dateRanges from it
                if (isRangeSearch) {
                    dateRanges = this.dowRangesToDateRanges(dowRangeOptions, searchDateWindow[0], searchDateWindow[1])
                }
                else {
                    const nextWeekendReferenceDate = searchDateWindows[0];
                    let firstSearchDate = nextWeekendReferenceDate.slice(); // Slice to create a clone of string
                    if (this.getDow(firstSearchDate) === sat) {
                        firstSearchDate = this.addDays(firstSearchDate, 3);
                    }
                    else if (this.getDow(firstSearchDate) === sun) {
                        firstSearchDate = this.addDays(firstSearchDate, 2);
                    }
                    else if (this.getDow(firstSearchDate) === mon) {
                        firstSearchDate = this.addDays(firstSearchDate, 1);
                    }
                    const lastSearchDate = this.addDays(firstSearchDate, 7);
                    dateRanges = this.dowRangesToDateRanges(dowRangeOptions, firstSearchDate, lastSearchDate)
                }
            }
        })
        return dateRanges;
    },
    // Turn day-of-week numbers into date ranges
    dowRangesToDateRanges(dowRanges, firstSearchDate, lastSearchDate) {
        if (!(Array.isArray(dowRanges)) || dowRanges.length === 0) { return [] }

        let resultDateRanges = [];  // Array of arrays of length 2 [checkinDateStr, checkoutDateStr]
        dowRanges.forEach(dowRange => {
            // console.log(`For DOW`, dowRange);

            let possibleDateRange = []; // [checkinDateStr, checkoutDateStr]
            let currentDate = firstSearchDate.slice();
            let rangeIndex = 0; // 0 is checkin, 1 is checkout
            // console.log('currentDate', currentDate, 'firstDate', firstDate, 'lastDate', lastDate);
            // console.log(this.stringToDate(currentDate), this.stringToDate(lastDate), this.stringToDate(currentDate) < this.stringToDate(lastDate));
            while (this.stringToDate(currentDate) < this.stringToDate(lastSearchDate)) {
                const currentDow = this.getDow(currentDate);
                const checkinDow = dowRange[rangeIndex];
                // console.log('currentDate', currentDate);
                // console.log(`currentDow (${currentDow}) === checkinDow (${checkinDow})`, currentDow === checkinDow)
                if (currentDow === checkinDow) {
                    // console.log('Pushing', currentDate)
                    possibleDateRange.push(currentDate);
                    rangeIndex = 1; // Increment it to checkout date index
                }
                if (possibleDateRange.length === 2) {
                    // console.log('Pushing to arrayOfWeekendDateRanges', possibleDateRange);
                    resultDateRanges.push(possibleDateRange);
                    possibleDateRange = []; // Reset it to empty to start over again
                    rangeIndex = 0; // Reset it to checkin date index
                }
                // currentDate.setDate(currentDate.getDate() + 1);
                currentDate = this.addDays(currentDate, 1);
            }
        })
        resultDateRanges.sort();
        return resultDateRanges;
    },
    // Get array of dates (dates of stay) between but not including checkout date
    dateRangesToDateSets(arrayOfDateRanges) { // [[checkin, checkout]]
        let arrayOfDateSets = [];
        arrayOfDateRanges.forEach(dateRange => {
            // console.log('arrayOfDateRanges.forEach(dateRange', dateRange);
            if (dateRange.length === 0) { return }
            const startDateTime = new Date(dateRange[0] + 'T00:00:00Z');
            const endDateTime = new Date(dateRange[1] + 'T00:00:00Z');

            let dateSetArray = [];
            let currentDateTime = startDateTime;
            while (currentDateTime < endDateTime) {
                dateSetArray.push(currentDateTime.toISOString().substring(0, 10));
                currentDateTime.setDate(currentDateTime.getDate() + 1);
            }
            // console.log('dateSetArray', dateSetArray)
            arrayOfDateSets.push(dateSetArray);
        });
        // console.log('arrayOfDateSets', arrayOfDateSets);
        return arrayOfDateSets;
    },
    // Format dates for display
    formatDateRange(dataRange) {
        const availableDateRange = [dataRange[0], this.addDays(dataRange[dataRange.length - 1], 1)];

        // Date formatting
        const dateStart = this.stringToDate(dataRange[0]);
        const dateEnd = this.stringToDate(dataRange[1]);
        const intlDateOptionsStart = { month: 'short', day: 'numeric', weekday: 'short' };
        const intlDateOptionsEnd = dateEnd.getMonth() === dateStart.getMonth() ? { day: 'numeric' } : { month: 'short', day: 'numeric' };
        const firstAvailableStart = new Intl.DateTimeFormat('en-US', intlDateOptionsStart).format(dateStart);
        const firstAvailableEnd = new Intl.DateTimeFormat('en-US', intlDateOptionsEnd).format(dateEnd);

        return firstAvailableStart + ' - ' + firstAvailableEnd;
    },
    // Get month name from INTL library
    getMonthName(monthNum, lengthType) { // monthNum should be base 0; lengthType can be 'long' or 'short'
        if (typeof monthNum !== 'number') {
            console.log('this.getMonthName requires a month number');
            return '';
        }
        const year = (new Date()).getFullYear(); // What year is used doesn't matter
        const month = monthNum; // JavaScript month is base 0
        const day = 1;
        const intlDate = new Date(year, month, day);
        const intlDateOptions = { month: lengthType };
        const monthName = new Intl.DateTimeFormat('en-US', intlDateOptions).format(intlDate);
        return monthName;
    },
    // Get an array of year-months (yyyy-mm) that an array of dates span; month is 1 based (like calendar)
    getUniqueYearMonths(arrayOfDateRanges) {
        const arrayOfDateSets = this.dateRangesToDateSets(arrayOfDateRanges);
        const arrayOfYearMonth = arrayOfDateSets.flat().map(str => str.substring(0, 7));
        const uniqueArrayOfYearMonth = [...new Set(arrayOfYearMonth)];
        return uniqueArrayOfYearMonth;
    },
    addDays(dateStr, numDays) {
        if (typeof dateStr != 'string') {
            console.log('addDates requires a string')
            return
        }
        let result = this.stringToDate(dateStr);
        result.setDate(result.getDate() + numDays);
        return this.dateToString(result);
    },
    addMonths(dateStr, numMonths) {
        if (typeof dateStr != 'string') {
            console.log('addDates requires a string')
            return
        }
        let currentDate = this.stringToDate(dateStr);
        const result = new Date(currentDate.getFullYear(), currentDate.getMonth() + numMonths, currentDate.getDate());
        return this.dateToString(result);
    },
    getDow(dateStr) {
        if (typeof dateStr != 'string') {
            console.log('addDates requires a string')
            return
        }
        let result = this.stringToDate(dateStr);
        return result.getDay();
    },
    dateToString(date) {
        if (typeof date !== 'object') {
            console.log('Should be a date');
            return;
        }
        // Solution from https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
        // const tzo = -date.getTimezoneOffset();
        const pad = function (num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate())
    },
    stringToDate(dateParameter) {
        if (Array.isArray(dateParameter)) {
            const arrayOfDates = dateParameter.map(str => {
                const dateParts = str.split('-');
                return new Date(dateParts);
            })
            return arrayOfDates;
        }
        else if (typeof dateParameter === 'string') {

            const dateParts = dateParameter.split('-');
            return new Date(dateParts);
        }
        else {
            return
        }
    }
}



export default dateEngine;