import React, { useState, useRef, useEffect } from 'react';
import './FlexDatesConsecutiveDays.css';
import DateEngine from '../../util/DateEngine';
import Button from '../Button/Button';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function FlexDatesConsecutiveDays() {
    // Query params to set state from the selected suggestion
    const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);

    // On mount, set consecutive days to 1, it not already defined
    useEffect(() => {
        const defaultDays = consecutiveDays == undefined || consecutiveDays == null ? 1 : consecutiveDays;
        setConsecutiveDays(defaultDays);
    }, [])

    function handleIncrementConsecutiveDays() {
        if (consecutiveDays === null || consecutiveDays === undefined) {
            setConsecutiveDays(1)
        }
        if (consecutiveDays < 14) {
            setConsecutiveDays(consecutiveDays + 1);
        }
    }

    function handleDecrementConsecutiveDays() {
        if (consecutiveDays === null || consecutiveDays === undefined) {
            setConsecutiveDays(1)
        }
        else if (consecutiveDays >= 2) {
            setConsecutiveDays(consecutiveDays - 1);
        }
    }

    return (
        <div className='FlexDatesConsecutiveDays'>
            <label>Nights</label>
            <div className='FlexDatesConsecutiveDays-input-container'>
                <Button type='secondary' size='sm' onClick={handleDecrementConsecutiveDays}>-</Button>
                <div className='FlexDatesConsecutiveDays-value'>{consecutiveDays}</div>
                <Button type='secondary' size='sm' onClick={handleIncrementConsecutiveDays}>+</Button>
            </div>
        </div>
    )

}

export default FlexDatesConsecutiveDays