import React, { useState } from 'react';
import './LocationInputButton.css';
import InputModal from '../InputModal/InputModal';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function LocationInputButton(props) {
    // Access parameter states
    const [campground, setCampground] = useQueryParam('id', StringParam); // This is used only for FacilityPage
    const [placeLabel, setPlaceLabel] = useQueryParam('place', StringParam);
    const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
    const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);

    // Generalize the state of the location input
    const hasCampground = campground !== undefined && campground !== null;
    const hasPlaceLabel = placeLabel !== undefined && placeLabel !== null;
    const hasLatitudeLongitude = latitude !== undefined && latitude !== null && typeof latitude === 'number' && longitude !== undefined && longitude !== null && typeof longitude === 'number';

    // What shows in the button
    // This logic is duplicated in LocationSearchInput. TODO: DRY
    let buttonText = 'Where do you want to go camping?';
    if (hasPlaceLabel) {
        buttonText = decodeURIComponent(placeLabel);
    }
    else if (!hasPlaceLabel && hasCampground) {
        buttonText = 'Campground ' + campground;
    }
    else if (hasLatitudeLongitude && !hasPlaceLabel) {
        buttonText = 'Map Location'
    }


    // Handle for opening dialog
    // Use state to control whether the dialog is open or closed
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = () => {
        setDialogIsOpen(true);
    }
    const closeDialog = () => setDialogIsOpen(false);
    const modalStyleToggle = { display: dialogIsOpen ? "block" : "none" };

    const handleOpenLocationSearchInput = (e) => {
        e.preventDefault(); // Stops it from executing onload
        openDialog();
    }

    return (
        <div>
            <div style={modalStyleToggle} className='LocationInputButton-modal-container'>
                <InputModal
                    closeDialog={closeDialog}
                    inputs={props.inputs}
                />
            </div>
            <div className='LocationInputButton' onClick={handleOpenLocationSearchInput}>{buttonText}</div>
        </div>
    )
}

export default LocationInputButton