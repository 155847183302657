import React from 'react';
import './AboutPage.css';


class AboutPage extends React.Component {

  render() {
    return (
      <div className="AboutPage">
        <h3>About</h3>
        <p>Find a place to camp faster, with less stress</p>
      </div>
    );
  }
}

export default AboutPage;
