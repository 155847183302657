import React, { useState, useEffect } from 'react';
import './FacilityCardInViewWrapper.css';
import { useInViewEffect } from 'react-hook-inview';
import FacilityCard from '../FacilityCard/FacilityCard';

function FacilityCardInViewWrapper(props) {
    const [isVisible, setIsVisible] = useState(false)

    const ref = useInViewEffect(
        ([entry], observer) => {
            console.log('Card is intersecting', props.facilityID, entry.isIntersecting)
            if (entry.isIntersecting) {
                observer.unobserve(entry.target)
            }
            setIsVisible(entry.isIntersecting)
        },
        { threshold: 0 },
    )

    return <div className='FacilityCardInViewWrapper' ref={ref}>{isVisible ? <FacilityCard {...props} /> : ''}</div>
}

export default FacilityCardInViewWrapper;