import React, { useState, useEffect } from 'react';
import './HomePage.css';
// import LocationSelectionSet from '../../componentsOld/LocationSelectionSet/LocationSelectionSet';
// import DateSelectionSet from '../../componentsOld/DateSelectionSet/DateSelectionSet';
// import DateEngine from '../../util/DateEngine';
import LocationInputButton from '../LocationInputButton/LocationInputButton';
import BrandingLogo from '../BrandingLogo/BrandingLogo';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';
import { getLocationProps } from 'use-query-params/lib/QueryParamProvider';


function HomePage(props) {
    // Query params storing states
    const [campground, setCampground] = useQueryParam('id', StringParam); // This is used only for FacilityPage
    const [placeLabel, setPlaceLabel] = useQueryParam('place', StringParam);
    const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
    const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);
    const [userDefinedDateRanges, setUserDefinedDateRanges] = useQueryParam('dates', DelimitedArrayParam);
    const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);
    const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);
    const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);
    const [nextMonths, setNextMonths] = useQueryParam('nextmonths', NumberParam);
    const [equipment, setEquipment] = useQueryParam('equipment', DelimitedArrayParam);
    const [experience, setExperience] = useQueryParam('experience', StringParam); // This might change to "activities" 

    // Generalize the state of the location input
    const hasCampground = campground !== undefined && campground !== null;
    const hasPlaceLabel = placeLabel !== undefined && placeLabel !== null;
    const hasLatitudeLongitude = latitude !== undefined && latitude !== null && typeof latitude === 'number' && longitude !== undefined && longitude !== null && typeof longitude === 'number';

    /* ----- To Move to searchInputs component
    // Generalize the state of date inputs
    const userDefinedDateRangesIsEmpty = (userDefinedDateRanges === undefined || userDefinedDateRanges === null || userDefinedDateRanges.length === 0);
    const monthsOfYearIsEmpty = (monthsOfYear === undefined || monthsOfYear === null || monthsOfYear.length === 0);
    const nextMonthsIsEmpty = (nextMonths === undefined || nextMonths === null);
    const dateConstraintsIsEmpty = userDefinedDateRangesIsEmpty && monthsOfYearIsEmpty && nextMonthsIsEmpty;
    const consecutiveDaysIsEmpty = (consecutiveDays === undefined || consecutiveDays === null);
    const weekendDaysIsEmpty = (weekendDays === undefined || weekendDays === null);

    // Provides the current query params as a string when navigating to another page
    const queryString = window.location.search

    // Conditionally render search button when a place has been selected
    const searchButton = hasCampground || hasLatitudeLongitude ? (
        <Link to={{
            pathname: '/search',
            search: queryString
        }} >
            <button>Search</button>
        </Link>
    ) : (
        <div></div>
    )

    -------- End searchInputs component ------*/

    return (
        <div className="HomePage">
            <div className='HomePage-contents'>
                <div className='HomePage-branding-logo'>
                    <BrandingLogo />
                </div>
                <div className="HomePage-location-input-button">
                    <LocationInputButton />
                </div>
                <div className='HomePage-browse-experiences-container'>
                    {/* <Link to={{
                        pathname: '/search?discovery=true'
                    }} >
                        <Button size='lg' type='primary'>Browse Experiences</Button>
                    </Link> */}
                </div>
            </div>
        </div>
    )
}

export default HomePage;
