import React, { useState, useRef } from 'react';
import './DateSelectionInput.css';
import DateEngine from '../../util/DateEngine';
import Button from '../Button/Button';
import DatePicker from "react-datepicker";
import FlexDatesConsecutiveDays from '../FlexDatesConsecutiveDays/FlexDatesConsecutiveDays';
import FlexDatesWeekendDays from '../FlexDatesWeekendDays/FlexDatesWeekendDays';
import FlexDatesMonths from '../FlexDatesMonths/FlexDatesMonths';
import "react-datepicker/dist/react-datepicker.css";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function DateSelectionInput() {
    // URL Date Range split character
    const drSpliter = '--';
    // Query params to set state from the selected suggestion
    const [userDefinedDateRanges, setUserDefinedDateRanges] = useQueryParam('dates', DelimitedArrayParam);
    const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);
    const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);
    const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);
    const [nextMonths, setNextMonths] = useQueryParam('nextmonths', NumberParam);

    // Generalize the state of date inputs
    const userDefinedDateRangesIsEmpty = (userDefinedDateRanges === undefined || userDefinedDateRanges === null || userDefinedDateRanges.length === 0);
    const monthsOfYearIsEmpty = (monthsOfYear === undefined || monthsOfYear === null || monthsOfYear.length === 0);
    const nextMonthsIsEmpty = (nextMonths === undefined || nextMonths === null);
    const flexDateConstraintsIsEmpty = userDefinedDateRangesIsEmpty && monthsOfYearIsEmpty && nextMonthsIsEmpty;
    const consecutiveDaysIsEmpty = (consecutiveDays === undefined || consecutiveDays === null);
    const weekendDaysIsEmpty = (weekendDays === undefined || weekendDays === null);

    // State of input
    const [selectionState, setSelectionState] = useState();
    // const [startDate, setStartDate] = useState(new Date("2014/02/08"));
    // const [endDate, setEndDate] = useState(new Date("2014/02/10"));

    // Date helpers
    const todayStr = DateEngine.dateToString(new Date());
    // const todayDt = DateEngine.stringToDate(todayStr);
    const tomorrowStr = DateEngine.addDays(todayStr, 1);
    // const tomorrowDt = DateEngine.stringToDate(tomorrowStr);
    const checkinStr = userDefinedDateRangesIsEmpty ? todayStr : userDefinedDateRanges[0].split(drSpliter)[0]
    const checkoutStr = userDefinedDateRangesIsEmpty ? tomorrowStr : userDefinedDateRanges[0].split(drSpliter)[1]


    // Handle for clicked buttons
    const handleChooseDates = () => {
        // setUserDefinedDateRanges();
        setSelectionState('dates');
        // Default dates
        // const checkinStr = todayStr;
        // const checkoutStr = tomorrowStr;
        setUserDefinedDateRanges([checkinStr + drSpliter + checkoutStr])
    }
    // Handle update checkin date
    const updateCheckinDate = (newCheckinDt) => {
        const newCheckinStr = DateEngine.dateToString(newCheckinDt);
        const newCheckoutStr = userDefinedDateRanges[0].split(drSpliter)[1];
        setUserDefinedDateRanges([newCheckinStr + drSpliter + newCheckoutStr]);
        // updateCheckoutDate(DateEngine.stringToDate(newCheckoutStr));
    }
    // Handle update checkin date
    const updateCheckoutDate = (newCheckoutDt) => {
        const newCheckoutStr = DateEngine.dateToString(newCheckoutDt);
        const newCheckinStr = userDefinedDateRanges[0].split(drSpliter)[0];
        setUserDefinedDateRanges([newCheckinStr + drSpliter + newCheckoutStr]);
    }
    // Handle for flex dates
    const handleChooseFlex = () => {
        // Clear the user defined dates (which take precedence)
        setUserDefinedDateRanges()
        setSelectionState('flex');
        // setConsecutiveDays(1);
    }


    // Render button choices, dates, or flex.
    if (selectionState !== 'dates' && selectionState !== 'flex' && userDefinedDateRangesIsEmpty && flexDateConstraintsIsEmpty) {
        return (
            <div className='DateSelectionInput'>
                <div className='DateSelectionInput-label'>
                    <p>When do you want to go camping?</p>
                </div>
                <div className='DateSelectionInput-date-type-selection'>
                    <Button fullWidth onClick={handleChooseFlex}>I'm flexible</Button>
                    <br />
                    <Button fullWidth onClick={handleChooseDates}>Specific Dates</Button>
                </div>
            </div>
        )
    }
    // If user wants to enter specific dates, render date pickers
    else if (!userDefinedDateRangesIsEmpty || selectionState === 'dates') {
        const dateCheckinDate = DateEngine.stringToDate(checkinStr)
        const dateCheckoutDate = DateEngine.stringToDate(checkoutStr)
        console.log('Dates', userDefinedDateRanges[0].split(drSpliter));
        const currentDateRangeStart = DateEngine.stringToDate(userDefinedDateRanges[0].split(drSpliter)[0]);
        const currentDateRangeEnd = DateEngine.stringToDate(userDefinedDateRanges[0].split(drSpliter)[1]);
        return (
            <div className='DateSelectionInput'>
                <p>When do you want to go camping?</p>
                <div className='DateSelectionInput-specific-dates'>
                    <>
                        <div className='DateSelectionInput-date-picker-wrapper'>
                            <DatePicker
                                wrapperClassName="DateSelectionInput-date-picker"
                                selected={currentDateRangeStart}
                                onChange={(date) => updateCheckinDate(date)}
                                selectsStart
                                startDate={currentDateRangeStart}
                                endDate={currentDateRangeEnd}
                                minDate={DateEngine.stringToDate(todayStr)}
                                maximum-scale={1}
                            />
                        </div>
                        <div className='DateSelectionInput-date-picker-wrapper'>
                            <DatePicker
                                wrapperClassName="DateSelectionInput-date-picker"
                                selected={currentDateRangeEnd}
                                onChange={(date) => updateCheckoutDate(date)}
                                selectsEnd
                                startDate={currentDateRangeEnd}
                                endDate={currentDateRangeEnd}
                                minDate={currentDateRangeEnd}
                                maximum-scale={1}
                            />
                        </div>
                    </>
                </div>
                <div className='DateSelectionInput-switch-date-type'>
                    <Button type='tertiary' onClick={handleChooseFlex}>Flexible dates instead</Button>
                </div>
            </div>
        )
    }
    // Else, choose flex dates
    else {
        return (
            <div className='DateSelectionInput'>
                <div className='DateSelectionInput-label'>
                    <p>When do you want to go camping?</p>
                </div>
                <div className='DateSelectionInput-flex-dates'>
                    <FlexDatesConsecutiveDays />
                    <FlexDatesWeekendDays />
                    <FlexDatesMonths />
                </div>
                <div className='DateSelectionInput-switch-date-type'>
                    <Button type='tertiary' onClick={handleChooseDates}>Specific dates instead</Button>
                </div>
            </div>
        )
    }
}

export default DateSelectionInput