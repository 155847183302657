import React, { useEffect, useState } from "react";
import "./FacilitiesList.css";
// import FacilityCard from "../FacilityCard/FacilityCard";
import FacilityCardInViewWrapper from "../FacilityCardInViewWrapper/FacilityCardInViewWrapper";

function FacilitiesList(props) {
  // Only load so many to not hammer API
  const listLimit = 10;
  const facilityListItemsTop = props.facilityListItems.slice(0, listLimit);

  let list = (
    <div className="FacilityList">
      <p>Loading</p>
    </div>
  )
  if (props.facilityListItems && props.isLoading === false) {
    list = facilityListItemsTop.map((facility) => {
      // console.log('Facility List Data', props.facilityListItems)
      // TODO: Use following resource to only fetch media and availability when card is in view: https://erikmartinjordan.com/visible-element-react
      return <FacilityCardInViewWrapper key={facility.FacilityID} facilityID={facility.FacilityID} nlDateObj={props.nlDateObj} dateConstraintsIsEmpty={props.dateConstraintsIsEmpty} />;
    })
  }

  // Facility count/page info
  const facilityCountInfo = props.isLoading || props.facilityListItems.length < listLimit ? (
    <div></div>
  ) : (
    <div className="FacilityList__facility-count">Showing {listLimit} of {props.facilityListItems.length}</div>
  )

  if (props === undefined || props.length < 1) {
    return (
      <div className="FacilityList">
        <p>Invalid Search</p>
      </div>
    )
  }
  else {
    return (
      <div className="FacilityList">
        {list}
        {facilityCountInfo}
      </div>
    )
  }
}

export default FacilitiesList;