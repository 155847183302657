import React, { useState, useEffect } from 'react';
import './BrandingLogo.css';
import { Link } from 'react-router-dom';

function BrandingLogo() {
    return (
        <Link to={{
            pathname: '/'
        }} >
            <h1 className='BrandingLogo'>Campsite Scout</h1>
        </Link>
    )
}

export default BrandingLogo