import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as ReactDOM from 'react-dom';
import { loadCss } from 'esri-loader';
import './FacilitiesMap.css'
import esriConfig from "@arcgis/core/config.js";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Query from "@arcgis/core/rest/support/Query";

function FacilitiesMap(props) {
    // States
    const [reactMap, setReactMap] = useState();
    const [reactView, setReactView] = useState();
    const [facilitiesInMapView, setFacilitiesInMapView] = useState();

    // by default loadCss() loads styles for the latest 4.x version
    // Resource: https://github.com/Esri/esri-loader#loading-styles
    loadCss();

    const mapRef = useRef(null);
    // Load once the DOM is there. Could also use useEffect. See Resource: https://www.youtube.com/watch?v=0fJmKSWURyU
    // Another resource: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
    const setMapRef = useCallback(node => {
        if (node !== null) {

            // API Key
            esriConfig.apiKey = 'AAPK59866ca1b80d414ab1bc46e3bc8711fad2iuwuIMVQqJ9pSF3bMH4MuU6FUh7pP5fbQbYUeUNAB5mdXxcf3YRB5rEMCY9Pmf';
            // Load map
            const map = new Map({
                basemap: "topo-vector"
            });

            // Create a view
            const view = new MapView({
                container: node,
                map: map,
                center: [props.longitude, props.latitude],
                zoom: 9,
                // ui: {
                //     components: ["attribution"] // empty the UI, except for attribution
                // }
            });
            // Prevent from loading too early
            if (!map || !view) { return; }

            // Create the PopupTemplate
            const popupTemplate = {
                // autocasts as new PopupTemplate()
                title: "{FacilityName}",
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "FacilityDescription",
                                label: "Description",
                            }
                        ]
                    }
                ]
            };

            // Load the feature layer
            const featureLayer = new FeatureLayer({
                // url: "https://services3.arcgis.com/s7NEwl0CVcT2Ta1D/arcgis/rest/services/USA_Campgrounds/FeatureServer/0", // Someones layer from usa campgrounds
                url: "https://services8.arcgis.com/pfLpYHODHEz6tIlj/arcgis/rest/services/facilities_api_v1/FeatureServer/0", // RIDB data - raw
                outFields: ["*"], // Used by queryFeatures
                popupTemplate: popupTemplate
            });
            map.add(featureLayer);

            // From this sample resource: https://developers.arcgis.com/javascript/latest/sample-code/featurelayerview-query/
            view.whenLayerView(featureLayer).then(function (layerView) {
                layerView.watch("updating", function (updating) {
                    if (!updating) {
                        // wait for the layer view to finish updating

                        // Is this needed?
                        layerView.createQuery()

                        // query all the features available for drawing.
                        layerView
                            .queryFeatures({
                                geometry: view.extent,
                                returnGeometry: true,
                                // orderByFields: ["latitude", "longitude"],
                                // maxRecordCount: 25
                                where: "FacilityTypeDescription = 'Campground' AND Reservable = 'true'"
                            })
                            .then(function (results) {
                                // Resulting features
                                const featureResults = results.features.map(f => f.attributes)
                                console.log('Map Query Result', featureResults);

                                // Stage map items in FacilitiesPage every time the map moves
                                props.setFacilityMapItems(featureResults);
                                // Update list loading state in FacilitiesPage
                                props.setFacilityListIsLoading(false);
                                // Increment query count
                                props.handleIncrementQueryCount();
                            })
                            .catch(function (error) {
                                console.error("query failed: ", error);
                            });
                    }
                });
            });

            console.log('facilitiesInMapView', facilitiesInMapView);

            // Save reference to the node
            mapRef.current = node

            // Tell React what needs to be cleaned up
            return () => { view && view.destroy(); }
        }
    }, [props.longitude]) // The map will reload when these things change
    // Add back in  props.nlDateObj once I can figure out how to make it not loop

    // Query for facilities
    // if (!mapRef?.view?.updating) {
    //     // Foo
    // }
    //     let query = featureLayer.createQuery();
    //     query.where = "1=1";
    //     query.outFields = ["*"];

    //     view.on("click", function (event) {
    //         let query = featureLayer.createQuery();
    //         query.geometry = view.toMap(event);  // the point location of the pointer
    //         query.distance = 2;
    //         query.units = "miles";
    //         query.spatialRelationship = "intersects";  // this is the default
    //         query.returnGeometry = true;
    //         query.outFields = ["*"];

    //         featureLayerView.queryFeatures(query)
    //             .then(function (response) {
    //                 // returns a feature set with features containing the
    //                 console.log('Map query results', response.features)
    //             });
    //     });
    // }

    return (
        <div className='FacilitiesMap'>
            <div className='FacilitiesMap-Map' ref={setMapRef}></div>
            {/* <div className='FacilitiesMap-Update-Btn'>Update Results</div> */}
        </div>
    )

}

export default FacilitiesMap;