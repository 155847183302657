import React, { useState, useRef, useEffect } from 'react';
import './FlexDatesWeekendDays.css';
import DateEngine from '../../util/DateEngine';
import Button from '../Button/Button';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function FlexDatesWeekendDays () {
    // Query params to set state from the selected suggestion
    const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);

    // On moount, set consecutive days to 1
    useEffect(() => {
        // Check for previously entered values
        const defaultWeekdaysDays = weekendDays == undefined || weekendDays == null ? undefined : weekendDays;
        setWeekendDays(defaultWeekdaysDays);
    }, [])

    // Handles for buttons
    function handleAnyDay () {
        setWeekendDays(undefined);
    }

    function handleOnlyWeekend () {
        setWeekendDays(2);
    }
    
    function handleSomeWeekend () {
        setWeekendDays(1);
    }
    

    // Buttons states
    const anyDateIsActive = weekendDays === null || weekendDays === undefined;
    const onlyWeekendIsActive = weekendDays === 2;
    const someWeekendIsActive = weekendDays === 1;
    
    return (
        <div className='FlexDatesWeekendDays'>
            <label>Weekends</label>
            <div className='FlexDatesWeekendDays-input-container'>
                <Button type='secondary' size='sm' inactive={!anyDateIsActive} onClick={handleAnyDay}>Any time</Button>
                <Button type='secondary' size='sm' inactive={!onlyWeekendIsActive} onClick={handleOnlyWeekend} >Only weekends</Button>
                <Button type='secondary' size='sm' inactive={!someWeekendIsActive} onClick={handleSomeWeekend} >Some weekends</Button>
            </div>
        </div>
    )

}

export default FlexDatesWeekendDays