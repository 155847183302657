import React, { useState, useRef, useEffect } from 'react';
import './FlexDatesMonths.css';
import DateEngine from '../../util/DateEngine';
import Button from '../Button/Button';
import {
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedArrayParam,
} from 'use-query-params';

function FlexDatesMonths () {
    // Query params to set state from the selected suggestion
    const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);

    // Array of months starting from this month
    let currentDate = new Date();
    let monthsArray = []
    for (let i = 0; i < 12; i++) {
        monthsArray.push({
            number: currentDate.getMonth() + 1,
            label: currentDate.toLocaleString("en-US", { month: "short" })
        })
        currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }


    // On moount, set consecutive days to 1
    useEffect(() => {
        // Check for previously entered values
        const defaultMonthsOfYear = monthsOfYear == undefined || monthsOfYear == null ? null : monthsOfYear;
        setMonthsOfYear(defaultMonthsOfYear);
    }, [])

    // Handles for buttons
    function handleToggleMonth (monthSelected) {
        // If monthsOfYear is undefined, set to monthSelected
        if (monthsOfYear === null || monthsOfYear === undefined) {
            setMonthsOfYear([monthSelected]);
        }
        // If month already in URL param, remove it
        else if (monthsOfYear.includes(monthSelected.toString())) {
            const newArray = monthsOfYear.filter(value => { 
                return value !== monthSelected.toString();
            });
            setMonthsOfYear(newArray, 'pushin');
        }
        // If not in URL param, add it
        else {
            const newArray = [...monthsOfYear, monthSelected]
            setMonthsOfYear(newArray, 'pushin');
        }
    }

    // Buttons states
    // const anyDateIsActive = weekendDays === null || weekendDays === undefined;
    // const onlyWeekendIsActive = weekendDays === 2;
    // const someWeekendIsActive = weekendDays === 1;


    return (
        <div className='FlexDatesMonths'>
            <label>Months</label>
            <div className='FlexDatesMonths-input-container'>
                {monthsArray.map(month => {
                    const isActive = monthsOfYear?.includes(month.number.toString());
                    return <Button key={month.number} type='secondary' size='sm' inactive={!isActive} onClick={(e) => { handleToggleMonth(month.number)}}>{month.label}</Button>
                })}
            </div>
        </div>
    )

}

export default FlexDatesMonths