import React from 'react';
// import { theme } from '../../tailwind.config.js' // Am I supposed to import this?
import '../../index.css'; // Imports tailwind... I think
import './Button.css'

function Button({ size = 'md', type = 'primary', inactive, fullWidth, onClick, children }) {
    // Resource: Method 2: https://www.smashingmagazine.com/2020/05/reusable-react-components-tailwind/

    const sizeClasses = {
        sm: 'Button-sm',
        md: 'Button-md',
        lg: 'Button-lg'
    }
    
    const typeClasses = {
        primary: "Button-primary",
        secondary: "Button-secondary",
        tertiary: "Button-tertiary"
    }

    const widthClass = fullWidth ? 'w-full' : '';

    const inactiveClass = inactive ? 'Button-inactive' : '';

    const classes = 'Button ' + sizeClasses[size] + ' ' + typeClasses[type] + ' ' + widthClass + ' ' + inactiveClass;

    return <button className={classes} onClick={onClick}>{children}</button>
}

export default Button;
