import AvailabilityDataStore from './AvailabilityDataStore'
import DateEngine from './DateEngine'

const nextAvailable = {
    async query(facilityID, consecutiveDays = 1, weekendDays = undefined) {
        // From nlDateObj, we need nights and weekendDays but don't need nextMonths
        const nlDateObj = {
            consecutiveDays: consecutiveDays,
            dateSearchWindow: {
                monthsOfYear: undefined,
                nextMonths: 1,
            },
            userDefinedDateRanges: undefined,
            weekendDays: weekendDays
        }
        // TODO: Not sure if we can assume a 6 month booking window for EVERY campground. Yosemite might be different.
        let monthsOfReservationWindow = 6;
        for (var i = 0; i <= monthsOfReservationWindow; i++) {
            nlDateObj.dateSearchWindow.nextMonths = i;
            // console.log(nlDateObj)
            const data = await AvailabilityDataStore.query(facilityID, nlDateObj);
            console.log('Next Available data for facility', facilityID, data);

            // TODO: Update to stop when the facility HAS availability
            if (data && 'calendarDatesWithAvailability' in data && Object.keys(data.calendarDatesWithAvailability).length > 0) {
                return data;
                break;
            }
        }
    }
}

export default nextAvailable;