import React, { useState, useEffect } from 'react';
import './FacilityCard.css';
import FetchFacilityFromRIDB from '../../util/FetchFacilityFromRIDB';
import FetchFacilityRatingFromRIDB from '../../util/FetchFacilityRatingFromRIDB';
import AvailabilityDataStore from '../../util/AvailabilityDataStore';
import DateEngine from '../../util/DateEngine';
import NextAvailable from '../../util/NextAvailable';
import { Link } from 'react-router-dom';
import { capitalCase } from "change-case";
import { useInView } from 'react-hook-inview'
import TextLoop from "react-text-loop";

function FacilityCard(props) {
    // TODO: Only fetch media and availability when partially in viewport. Use https://www.npmjs.com/package/react-visibility-sensor
    // Use state to hold the availability data and to rerender component when data is received
    const [facility, setFacility] = useState({})
    const [facilityIsLoading, setFacilityIsLoading] = useState(true);
    const [facilityRating, setFacilityRating] = useState({})
    const [facilityRatingIsLoading, setFacilityRatingIsLoading] = useState(true)
    const [availability, setAvailability] = useState({});
    const [availabilityIsLoading, setAvailabilityIsLoading] = useState(true);
    const [availabilityIndex, setAvailabilityIndex] = useState(0);
    const [availabilityFetchType, setAvailabilityFetchType] = useState();
    const [isReservable, setIsReservable] = useState(true);

    // Destructure props
    const { facilityID, nlDateObj, dateConstraintsIsEmpty } = props;

    // Detect when card is in viewport
    const [ref, isVisible] = useInView({
        threshold: 1,
    })

    // Fetch the data on load
    useEffect(() => {
        console.log('Loading card for', facilityID)
        // Fetch info and media for card
        fetchFacilityData();
        fetchFacilityAvailability();
        fetchFacilityRating();
    }, [nlDateObj]);

    // Manager to fetch for data, and render items from RIDB
    async function fetchFacilityData() {
        // First get facilities
        const facility = await FetchFacilityFromRIDB.fetchFacilityByID(facilityID);
        const facilityJson = await facility.json();
        console.log('Facility Data', facilityJson)
        // Availability data will be fetched by each FacilityCard so that users can browse while it loads
        setFacility(facilityJson);
        setFacilityIsLoading(false);
    }

    async function fetchFacilityRating() {
        // First get facilities
        const facility = await FetchFacilityRatingFromRIDB.fetchFacilityRatingByID(facilityID);
        const facilityRatingJson = await facility.json();
        console.log('Facility Rating', facilityRatingJson)
        // Rating data will be fetched by each FacilityCard so that users can browse while it loads
        setFacilityRating(facilityRatingJson);
        setFacilityRatingIsLoading(false);
    }

    async function fetchFacilityAvailability() {
        console.log('fetchFacilityAvailability', facilityID, nlDateObj)
        // If no date constraints, get next available
        // Respect consecutiveDays if present
        if (!nlDateObj || dateConstraintsIsEmpty) {
            // console.log('Date parameters are empty, getting next available for facility', facilityID);
            const facilityAvailability = await NextAvailable.query(facilityID, nlDateObj.consecutiveDays, nlDateObj.weekendDays);
            setAvailability(facilityAvailability);
            setAvailabilityFetchType('nextAvailable');
            setAvailabilityIsLoading(false);
        }
        else {
            const facilityAvailability = await AvailabilityDataStore.query(facilityID, nlDateObj)
            console.log('FacilityCard Availability Data', facilityAvailability)
            setAvailability(facilityAvailability);
            setAvailabilityIsLoading(false);
            setAvailabilityFetchType('definedMonths')
        }
    }

    // Rating Info
    let ratingText = '';
    if (!facilityRatingIsLoading && facilityRating && facilityRating.average_rating) {
        const ratingNum = Math.round(facilityRating.average_rating * 10) / 10
        const ratingCount = facilityRating.number_of_ratings;
        ratingText = `${ratingNum} stars (${ratingCount})`;
    }

    // Get media and info for card
    let availabilityText = '';
    const hasAvailability = availability && 'calendarDatesWithAvailability' in availability && Object.keys(availability.calendarDatesWithAvailability).length > 0;
    // If reservable, label it
    if (!isReservable) {
        availabilityText = 'Not reservable';
    }
    // Loading state
    else if (availabilityIsLoading) {
        availabilityText = 'Loading...';
    }
    // Has availability data AND has an available date
    else if (hasAvailability) {
        let availabilityArray = [];
        availability.desiredDateRanges.forEach(dateRange => {
            if (dateRange.campsitesAvailable.length > 0) {
                availabilityArray.push(
                    {
                        dateText: dateRange.shortFormDateRange,
                        siteCount: dateRange.campsitesAvailable.length
                    }
                )
            }
        })
        availabilityText = (
            <TextLoop interval={3000}>
                <span>Available sites</span>
                {availabilityArray.map(date => {
                    return (
                        <div key={date.dateText}>
                            <span className='facility-card__availability-tag__date'>{date.dateText}</span>
                            <span className='facility-card__availability-tag__site-count'>{date.siteCount} sites</span>
                        </div>
                    )
                })}
            </TextLoop>
        )
    }
    // Has availability data AND does not have an available dates
    else {
        // Depending on whether it was a comprehensive search or not... provide info as to why
        availabilityText = availabilityFetchType === 'nextAvailable' ? 'Booked thru reservation window' : 'No availability'
    }

    // Card content
    let cardContent = '';

    // Render empty card when loading
    if (facilityIsLoading) {
        cardContent = (
            <div className="facility-card">
                <div className="facility-card__media-frame">
                </div>
                <div className="facility-card__content">
                    <div className="facility-card__content__facility-name"></div>
                    <p className="facility-card__content__org-name">
                    </p>
                    <div className="facility-card__availability">
                        {/* <span className="facility-card__availability-tag">
                            </span> */}
                    </div>
                </div>
            </div >
        )
    }
    // If we got the facility data
    else if (Object.keys(facility).length === 0) {
        console.log(`Could not load campground ${facilityID} information`)
        cardContent = (
            <div className="facility-card">
                <div className="facility-card__media-frame">
                </div>
                <div className="facility-card__content">
                    Could not load campground information
                </div>
            </div >
        )
    }
    // If facility data includes a name
    else if (facility && 'FacilityName' in facility) {
        const hasMedia = facility && 'MEDIA' in facility && facility.MEDIA.length > 0;
        let imgURL = '';
        let media = <div style={{ backgroundColor: 'gray' }}></div>;
        let facilityName = capitalCase(facility.FacilityName);
        // If org name doesn't exist, assign it empty string 
        const orgName = facility.ORGANIZATION[0] && facility.ORGANIZATION[0].OrgName ? facility.ORGANIZATION[0].OrgName : '';
        if (hasMedia) {
            imgURL = facility.MEDIA[0].URL
            media = <img className="facility-card__media" src={imgURL} alt="Mountain" />;
        }
        // console.log('FACILITY CARD MEDIA', facility.MEDIA[0].URL)

        // Provides the current query params as a string when navigating to another page
        const queryString = window.location.search;

        cardContent = (
            <div className="facility-card">
                <div className="facility-card__media-frame">
                    {media}
                </div>
                <div className="facility-card__content">
                    {/* <Link to={'/campground?id=' + facility.FacilityID} >
                        <div className="facility-card__content__facility-name">{facilityName}</div>
                    </Link> */}
                    <a href={`https://www.recreation.gov/camping/campgrounds/${facility.FacilityID}`} target="_blank">
                        <div className="facility-card__content__facility-name">{facilityName}</div>
                    </a>
                    <p className="facility-card__content__org-name">
                        {orgName}
                    </p>
                    <div className="facility-card__content__footer">
                        <span className="facility-card__availability-tag">
                            {availabilityText}
                        </span>
                        <span className="facility-card__rating-tag">
                            {ratingText}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    else {
        console.log(`Could not load campground ${facilityID} information`)
        cardContent = (
            <div className="facility-card">
                <div className="facility-card__media-frame">
                </div>
                <div className="facility-card__content">
                    Could not load campground information
                </div>
            </div>
        )
    }
    return cardContent
}

export default FacilityCard; 