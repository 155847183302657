// See the following for technique to hide API key https://medium.com/better-programming/how-to-hide-your-api-keys-c2b952bc07e6
// TODO: update technique for handling CORS. I have to create my own proxy. Can't rely on cors-anywhere.herokuapp.com outside of development (https://github.com/Rob--W/cors-anywhere/issues/301)
const RIDB = {
    async fetch(campgroundId, yearMonth) {
        console.log(`Fetching data for campground ${campgroundId} for ${yearMonth} from RIDB`);
        // RIDB returns data for in one-month chunks
        // Provide yearMonth in 'yyyy-mm' format 
        const fullDate = yearMonth + '-01';
        const url = `https://ridb.recreation.gov/api/availability/camping/${campgroundId}?date=${fullDate}`
        const urlWithApiKey = url + '&apikey=' + process.env.REACT_APP_RIDB_API_KEY;
        // const urlCorsAnywhere = 'https://cors-anywhere.herokuapp.com/' + urlWithApiKey; 
        const urlCorsAnywhere = 'https://camp-scout-proxy.herokuapp.com/' + urlWithApiKey; 
        const result = await fetch(urlCorsAnywhere,
            {
                // TODO: Make apikey private. Include as a request header? Not sure how to do this. See https://www.codecademy.com/learn/webdev-intermediate-javascript/modules/intermediate-javascript-requests/cheatsheet
                // headers: {
                //     Authorization: `Bearer ${apiKey}`
                // }
            }).then(response => {
                // console.log(response);
                if(response.ok) {
                    // console.log('THE RESPONSE WAS OK');
                    return response.json();
                }
                throw new Error('Request failed!');
            }, networkError => {
                console.log(networkError.message)
            }).then(jsonResponse => {
                // console.log('Fetch Response', jsonResponse)
                if('campsites' in jsonResponse) {
                    return jsonResponse.campsites;
                }
                else {
                    console.log('No jsonResponse from RIDB')
                    return {};
                }
            });
        // console.log('I awaited for the promise to return', result);
        return result;
    },

}

export default RIDB