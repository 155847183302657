import React, { useState } from 'react';
import './FilterChangeInputButton.css';
import FilterChangeModal from '../FilterChangeModal/FilterChangeModal';
import Button from '../Button/Button';

function DateChangeInputButton(props) {

    // TODO: Make button text summarize the current filters if not null
    const buttonText = 'Add filters';

    // Handle for opening dialog
    // Use state to control whether the dialog is open or closed
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = () => {
        setDialogIsOpen(true);
    }
    const closeDialog = () => setDialogIsOpen(false);
    const modalStyleToggle = { display: dialogIsOpen ? "block" : "none" };

    const handleOpenFilterChangeModal = (e) => {
        e.preventDefault(); // Stops it from executing onload
        openDialog();
    }

    return (
        <div className='FilterChangeInputButton'>
            <div style={modalStyleToggle} className='FilterChangeInputButton-modal-container'>
                <FilterChangeModal
                    closeDialog={closeDialog}
                />
            </div>
            <div className='FilterChangeInputButton-button' onClick={handleOpenFilterChangeModal}>
                <Button type='secondary' size='sm'>{buttonText}</Button>
            </div>
        </div>
    )
}

export default DateChangeInputButton
