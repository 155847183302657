import React, { useState } from 'react';
import './DateChangeInputButton.css'
import Button from '../Button/Button';
import DateChangeModal from "../DateChangeModal/DateChangeModal"
import DateEngine from '../../util/DateEngine';
import {
    useQueryParam,
    QueryParams,
    StringParam,
    NumberParam,
    ArrayParam,
    DelimitedArrayParam,
    withDefault,
} from 'use-query-params';
// import DateSelectionInput from "../DateSelectionInput/DateSelectionInput";

function DateChangeInputButton(props) {
    const [userDefinedDateRanges, setUserDefinedDateRanges] = useQueryParam('dates', DelimitedArrayParam);
    const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);
    const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);
    const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);
    const [nextMonths, setNextMonths] = useQueryParam('nextmonths', NumberParam);

    // Check states in query params
    const userDefinedDateRangesIsEmpty = (userDefinedDateRanges === undefined || userDefinedDateRanges === null || userDefinedDateRanges.length === 0);
    const monthsOfYearIsEmpty = (monthsOfYear === undefined || monthsOfYear === null || monthsOfYear.length === 0);
    const nextMonthsIsEmpty = (nextMonths === undefined || nextMonths === null);
    const dateConstraintsIsEmpty = userDefinedDateRangesIsEmpty && monthsOfYearIsEmpty && nextMonthsIsEmpty;
    const consecutiveDaysIsEmpty = (consecutiveDays === undefined || consecutiveDays === null);
    const weekendDaysIsEmpty = (weekendDays === undefined || weekendDays === null);
    // Might need to move into useEffect
    const nlDateObj = {
        userDefinedDateRanges: userDefinedDateRanges,
        consecutiveDays: consecutiveDays,
        weekendDays: weekendDays,
        dateSearchWindow: {
            monthsOfYear: monthsOfYear,
            nextMonths: nextMonths
        }
    }

    // Get number of searched date ranges
    const dateRanges = DateEngine.nlDatePropertiesToDateRanges(nlDateObj);
    const numDateRanges = dateRanges.length;

    // TODO: Make button text summarize the current date filter if not null
    let buttonText = 'Specify dates';
    if (numDateRanges > 1) {
        buttonText = `Dates (${numDateRanges})`;
    }
    console.log('DateChangeInputButton')

    // Handle for opening dialog
    // Use state to control whether the dialog is open or closed
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = () => {
        setDialogIsOpen(true);
    }
    const closeDialog = () => setDialogIsOpen(false);
    const modalStyleToggle = { display: dialogIsOpen ? "block" : "none" };

    const handleOpenDateChangeModal = (e) => {
        e.preventDefault(); // Stops it from executing onload
        openDialog();
    }

    return (
        <div className='DateChangeInputButton'>
            <div style={modalStyleToggle} className='DateChangeInputButton-modal-container'>
                <DateChangeModal
                    closeDialog={closeDialog}
                />
            </div>
            <div className='DateChangeInputButton-button' onClick={handleOpenDateChangeModal}>
                <Button type='secondary' size='sm'>{buttonText}</Button>
            </div>
        </div>
    )
}

export default DateChangeInputButton
