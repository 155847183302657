
const FetchFacilityFromRIDB = {
    // Fetch facilities from RIDB
    async fetchFacilityByID(FacilityID) { 
        const params = {
            full: true, // Doc uses 'fullDetail' or 'full' to get media etc but recreation.gov uses 'exact'
        }
        let urlParams = '';
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                urlParams += key + '=' + params[key] + '&'
            }
        }
        const url = 'https://ridb.recreation.gov/api/v1/facilities/' + FacilityID + '?' + urlParams;
        const urlWithApiKey = url + 'apikey=' + process.env.REACT_APP_RIDB_API_KEY;
        // const urlCorsAnywhere = 'https://camp-scout-proxy.herokuapp.com/' + urlWithApiKey; 
        const data = await fetch(urlWithApiKey);
        return data;
    }
}

export default FetchFacilityFromRIDB;