import React from 'react';
import './ContactPage.css';


class ContactPage extends React.Component {

  render() {
    return (
      <div className="ContactPage">
        <h3>Contact</h3>
        <p>Get in touch. I'd love to hear what you think</p>
        <a href="mailto:davidnyenhuis@gmail.com">Email Me</a>
      </div>
    );
  }
}

export default ContactPage;
