import React, { useState } from 'react';
import './FilterChangeModal.css';
import useKeypress from '../../hooks/useKeypress';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';

function DateChangeModal(props) {

    useKeypress('Escape', () => {
        props.closeDialog();
    });

    // Close dialog
    const handleCloseDialog = (e) => {
        e.preventDefault();
        props.closeDialog()
    }

    // Provides the current query params as a string when navigating to another page
    let queryString = window.location.search;
    // Check if it has flag to show map by default on mobile. For mobile, need the  map to load before the list
    const hasShowmap = queryString.includes('showmap=1');
    queryString = hasShowmap ? queryString : queryString + '&showmap=1'

    return (
        <div className='FilterChangeModal'>
            {/* <button onClick={handleCloseDialog}>X</button> */}
            <div className='FilterChangeModal-contents'>
                <p>Coming soon</p>
            </div>
            {/* <div className='DateChangeModal-apply'>
                <Link to={{
                    pathname: '/search',
                    search: queryString, 
                    key: queryString, // This is to trick it to re-render the page/component if already on it, though some say Math.random doesn't guarantee uniqueness
                    state: {
                        applied: true
                    }
                }} > 
                </Link>*/}
            <div className='FilterChangeModal-done-btn'>
                <Button type='secondary' size='sm' fullWidth onClick={handleCloseDialog} >Done</Button>
            </div>
        </div >
    )
}

export default DateChangeModal;